import styles from './profil.module.scss';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import useAuthStore from 'store/useAuth';
import React from 'react';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';

const Page = () => {
  const notifications = useNotification();

  const currentUser = useAuthStore(state => state.user);

  const logout = useAuthStore(state => state.logout);
  const resetPassword = useAuthStore(state => async () => {
    await state.resetPassword();
    const id = (Math.random() + 1).toString(36).substring(7);
    notifications.add({
      title: 'Passwort zurücksetzen Mail versendet',
      icon: 'check',
      id,
      type: 'success',
      handleClose: () => {
        // eslint-disable-next-line no-console
        console.log('close');
      },
    });
    setTimeout(() => {
      notifications.remove(id);
    }, 5000);
  });

  return (
    <Wrapper>
      <Layout
        title={currentUser ? `Willkommen ${currentUser?.name}` : 'Profil'}
      >
        {currentUser && (
          <Container size="lg">
            <br />
            <br />
            <Card>
              <Content className={styles.userInfo}>
                <strong>Email:</strong> {currentUser.email}
              </Content>
              <Content className={styles.userInfo}>
                <strong>Name:</strong> {currentUser.name}
              </Content>
              <Button theme="secondary" onClick={logout}>
                Logout
              </Button>
            </Card>
            <br />
            <Card>
              <Content>
                Sie erhalten auf die registrierte E-Mailadresse Anweisungen
                zugesendet zum Zurücksetzen ihres Passwortes
              </Content>
              <Button onClick={resetPassword}>Passwort zurücksetzen</Button>
            </Card>
            <br />
          </Container>
        )}
      </Layout>
    </Wrapper>
  );
};

export default Page;
